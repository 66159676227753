import React from 'react'
import CardValue from 'views/Home/components/CardValue'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { Flex } from '@macist-m/robinia-uikit'
import BigNumber from 'bignumber.js/bignumber'
import {
  useTotalSupply,
  useBurnedBalance,
  useCustomTokenBalance,
} from 'hooks/useTokenBalance'
import { BLOCKS_PER_YEAR } from 'config'
import { getCakeAddress } from 'utils/addressHelpers'
import { QuoteToken } from 'config/constants/types'

import {
  useFarms,
  usePriceCakeBusd,
  useTotalValue,
  usePriceBnbBusd,
} from '../../state/hooks'
import Socials from './Socials'

declare global {
  interface Window {
    ethereum: any
  }
}
const addToMetamask = function () {
  window.ethereum
    .request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: '0xf51903f5B838eDFDd4D0dDC8be2145863Eaf0032',
          symbol: 'WST',
          decimals: 18,
          image: `${window.location.origin}/images/favicons/apple-icon-72x72.png`,
        },
      },
    })
    .then((success) => {
      if (success) {
        console.log('WST successfully added to wallet!')
      } else {
        throw new Error('Something went wrong.')
      }
    })
    .catch(console.error)
}
const MainFooter = () => {
  const Box = styled.div`
    color: #8d694a;
    line-height: 2;
    margin-top: 25px;
  `
  const Values = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content:space-around;

  }
`
  const Text = styled.div`
color: #8d694a;
font-weight:${(props) => props.property };

  }
`

  return (
    <Values>
      <img style={{maxWidth:"120px",height:"120px",width:"100%"}} src="/images/matv.png" alt="rbs-ico" />
      <Box>
        <Text property='bold'>Products</Text>
        <a href="/pools">Auto Farm</a>
        <div>
          <a href="/bond">Bond</a>
        </div>
        <div>
          <a href="/dashboard">Dashboard</a>{' '}
        </div>
      </Box>
      <Box>
        <Text property='bold'>
          {' '}
          <a href="https://metavilldao.gitbook.io/whitepaper/introduce/metavill-dao">Docs</a>
        </Text>
        {/* <a href="https://steemit.com/@robinia/posts">News</a> */}
        <Text >
          <a href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/April/METAVILL%20LIFE%20Full%20Smart%20Contract%20Security%20Audit.pdf">
            Audit
          </a>
        </Text>
      </Box>
      <Box>
        <Text property='bold'>Service</Text>
        <Text>DAO</Text>
      </Box>

      <Box>
        <Text property='bold'>Community</Text>
        <Socials />
      </Box>
    </Values>
  )
}

export default MainFooter

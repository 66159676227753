import { MenuEntry } from '@macist-m/robinia-uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://dex.metavill.life/swap?outputCurrency=0xf51903f5B838eDFDd4D0dDC8be2145863Eaf0032',
      },
      {
        label: 'Liquidity',
        href: 'https://dex.metavill.life/add/BNB/0xf51903f5B838eDFDd4D0dDC8be2145863Eaf0032',
      },
    ],
  },
  {
    label: 'Auto Farm',
    icon: 'FarmIcon',
    href:'/pools'
  },
  {
    label: 'Lockup',
    icon: 'PoolIcon',
    href: '/lockup',
  },
  {
    label: 'Bond',
    icon: 'NftIcon',
    href:'/bond'
  },
  {
    label: 'Dashboard',
    icon: 'NftIcon',
    href:'/dashboard'
  },
    // {
    //   label: 'IFO',
    //   icon: 'GooseIcon',
    //   href: '/ifo',
    // },
  // {
  //   label: 'Bridge',
  //   icon: 'PoolIcon',
  //    href:'https://bridge.robiniaswap.com/'
  // },
  // {
  //   label: "Partner's Defi ",
  //   icon: 'TicketIcon',
  //   items: [
  //     {
  //       label: 'RobiniaSwap',
  //       href: 'https://robiniaswap.com/',
  //     },
  //     {
  //       label: 'Yanabu Farm',
  //       href: 'https://yanabu.com/',
  //     },
  //     {
  //       label: 'Honey Farm',
  //       href: 'https://honeyfarm.finance/',
  //     },
  //   ],
  //   },


  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://metavilldao.gitbook.io/whitepaper/introduce/metavill-dao',
     },
    {
    label: 'Audit',
    href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/April/METAVILL%20LIFE%20Full%20Smart%20Contract%20Security%20Audit.pdf',
    },
    {
    label: 'Price Chart',
    href: 'https://poocoin.app/tokens/0xf51903f5B838eDFDd4D0dDC8be2145863Eaf0032',
    },
    ],
  },


  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },


  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
